<template>
    <div class="page">
        <div class="title">
            <div class="name">运行统计</div>
            <div class="search">
                <Select v-model="formItem.villageId" placeholder="全部小区" clearable style="width:120px">
                    <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                        {{ item.v_village_name }}</Option>
                </Select>
                <Select v-model="formItem.vElevatorCode" placeholder="全部电梯" clearable style="width:120px">
                    <Option v-for="(item, index) in elevatorSelectList" :key="index" :value="item.v_elevator_code">
                        {{ item.v_elevator_code }}</Option>
                </Select>
                <DatePicker :value="formItem.dtReportTime" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="开始时间" @on-change="formItem.dtReportTime = $event" />
                <DatePicker :value="formItem.dtEndTime" @on-change="formItem.dtEndTime = $event" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="结束时间" />
                <Button style="margin-right:10px" type="primary" @click="searchList()">查询</Button>
                <!-- <Button type="primary" @click="exportRun()">导出</Button> -->
            </div>
        </div>
        <div class="content">
            <tblcomponents ref="tblcomponents" :formItem="formItem" :tabelConfigVal="tabelConfig" :tabelDataVal="tabelData" :tabelLoadingVal="tabelLoading" :totalRecordCntVal="totalRecordCnt" @search="searchList" data-index="vDeptId" />
        </div>
    </div>
</template>
<script>
import { getRunCountInfo, exportRunCountInfo } from '@/api/ststistics/index.js'


import {
    getElevatorsStatus,
    searchVillageSelectList
} from '@/api/home/index.js'
export default {
    data() {
        return {
            modaDetail: false,
            elevatorSelectList: [],
            faultImageList: '',
            model1: '',
            faultInfoDetail: {},
            videoUrl: '',
            searchLoading: false,
            open: false,
            tabelConfig: [{
                    title: '电梯编号',
                    key: 'v_elevator_name',
                    width: "180px"
                },

                {
                    title: '品牌',
                    key: 'brandName'
                },
                {
                    title: '电梯类型',
                    // key: 'i_elevator_use_type_name',
                    render: (h, params) => {
                        return h('div', [
                            h('font', params.row.i_elevator_use_type_name ? params.row.i_elevator_use_type_name : '直梯')
                        ])
                    }
                },
                {
                    title: '上次维保日期',
                    key: 'd_last_maintain_date'
                },
                {
                    title: '下次年检日期',
                    key: 'd_next_inspect_date'
                },
                {
                    title: '困人次数',
                    key: 'rescue?',
                    render: (h, params) => {
                        return h('div', [
                            h('font', params.row.rescue ? params.row.rescue : '0')
                        ])
                    }
                },
                {
                    title: '急修次数',
                    key: 'fault',
                    render: (h, params) => {
                        return h('div', [
                            h('font', params.row.fault ? params.row.fault : '0')
                        ])
                    }
                },
                {
                    title: '维保次数',
                    key: 'workOrderNum',
                    render: (h, params) => {
                        return h('div', [
                            h('font', params.row.workOrderNum ? params.row.workOrderNum : '0')
                        ])
                    }
                },
                {
                    title: '运行总次数',
                    key: 'run_count',
                    render: (h, params) => {
                        return h('div', [
                            h('font', params.row.run_count ? params.row.run_count : '0')
                        ])
                    }
                },
                {
                    title: '开关门次数',
                    key: 'door_count',
                    render: (h, params) => {
                        return h('div', [
                            h('font', params.row.door_count ? params.row.door_count : '0')
                        ])
                    }
                },
                // {
                //     title: '电瓶车入梯次数',
                //     key: 'door_count',
                //     render: (h, params) => {
                //         return h('div', [
                //             h('font', params.row.electro_mobile_count ? params.row.electro_mobile_count : '0')
                //         ])
                //     }
                // },
                {
                    title: '遮挡门次数',
                    key: 'doorStopNumber',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'font',
                                params.row.doorStopNumber ? params.row.doorStopNumber : '0'
                            )
                        ])
                    }
                }
            ],
            elevatorData: { villageId: '', vProjectId: '' },
            villageFormItemSelectList: [],
            tabelData: [],
            tabelLoading: false,
            totalRecordCnt: 0,
            formItem: {
                pageIndex: 1,
                pageSize: 10,
                vProjectId: '8089373891892543488',
                villageId: '',
                iElevatorType: '',
                vElevatorCode: '',
                dtEndTime: this.formatDate(0) + '',
                dtReportTime: this.formatDate(-365) + ''
            }
        }
    },
    created() {
        // 获取物业部门
        this.searchList()
        searchVillageSelectList(this.buildParams({})).then(res => {
            this.villageFormItemSelectList = res.info
        })
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    methods: {
        searchList() {
            this.searchLoading = true
            this.tabelLoading = true
            getRunCountInfo(this.buildParams(this.formItem)).then(res => {
                this.tabelData = res.info.list
                this.$refs['tblcomponents'].pageIndex = res.info.pageIndex
                this.$refs['tblcomponents'].pageSize = res.info.pageSize
                this.totalRecordCnt = res.info.totalRecordCnt
                this.tabelLoading = false
                this.searchLoading = false
            })
        },
        exportRun() {
            exportRunCountInfo(this.buildParams(this.formItem)).then(res => {
                console.log(res, 'res');
                const blob = res
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onload = e => {
                    const a = document.createElement('a')
                    a.download = `Shmashine-Elevator.xls`
                    a.href = e.target.result
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                    this.searchDownloadLoading = false
                }
                this.$Message.success('导出成功')
            }).catch(err => {
                this.$Message.error('导出失败')

            })
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
    },
    watch: {

        "formItem.villageId": function(newObj, OldObj) {
            if (newObj) {
                this.elevatorData.villageId = newObj
                getElevatorsStatus(this.elevatorData).then(res => {
                    this.elevatorSelectList = res.info
                })
            } else {
                this.elevatorData.vElevatorCode = ''
                this.elevatorSelectList = []
            }
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.ivu-modal-content {
    background-color: #f2f7fb;
}

/deep/.ivu-modal-header p,
/deep/.ivu-modal-header-inner {
    font-weight: 700;
    padding-left: 20px;
}

/deep/.ivu-modal-header {
    border: none;
}

/deep/.ivu-modal-body {
    padding-top: 0;
}

.ivu-select,
.ivu-date-picker {
    margin-right: 10px;
}

.title {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
}

.page {
    padding: 0 10px 0;
}

.content {
    padding: 10px;
}
</style>